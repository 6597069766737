import React, { useMemo } from 'react'
import { Col, Row, Container } from 'react-grid-system'
import { Alert, Box, InputPassword, Dropdown } from 'condolivre-ds'
import { InputText, Button, Text, Spinner } from 'condolivre-ds'
import styles from './Welcome.module.scss'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'
import { useState, useEffect } from 'react'
import CustomModal from '@/pages/digital-account/onboarding/components/custom-modal'
import Image from 'next/image'
import { useRouter } from 'next/router'
import {
    requestDigitalAccountLogin,
    requestDigitalAccountPreLogin,
    requestForgotPassword,
    unlockUserAccountRequest
} from '../../network'
import {
    DIGITAL_ACCOUNT_NETWORK_ERRORS,
    DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS,
    LOGIN_SUBSTEPS
} from '../../constants'
import {
    ROUTE_DASHBOARD,
    ROUTE_DIGITAL_ACCOUNT_DASHBOARD,
    ROUTE_DIGITAL_ACCOUNT_NEW_LOGIN,
    ROUTE_WELCOME
} from '@/config/routes'
import { useDigitalAccountAuth, useDigitalAccountUser } from '@/providers/DigitalAccountUser'
import { formatCpfCnpjString, onlyDigits } from '@/helpers/format'
import { requestUserName } from '@/pages/login/network'
import {
    enhanceDigitalAccountUser,
    getDigitalAccountUser,
    registerDigitalAccountCookies
} from '@/helpers/cookies'
import { SupplierStatus } from '@/pages/supplier/constants'
import { useAuth } from '@/providers/Auth'
import UserBlocked from '../UserBlocked/UserBlocked'
import UnlockUser from '../UnlockUser/UnlockUser'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import Modal from '@/components/Modal/Modal'

const CPF_LENGTH = 11
const MIN_PASSWORD_LENGTH = 6
const PASSWORD_LENGTH = 20

const RESET_PASSWORD_CONTENT = {
    USER_BLOCKED: 'USER_BLOCKED',
    SUCCESS_FORGOT_PASSWORD_EMAIL: 'SUCCESS_FORGOT_PASSWORD_EMAIL',
    SUCCESS_USER_BLOCKED_EMAIL: 'SUCCESS_USER_BLOCKED_EMAIL'
}

export default function Welcome() {
    const router = useRouter()
    const authContext = useAuth()
    const { setAuthenticated } = useDigitalAccountAuth()

    const [cpf, setCpf] = useState(router?.query?.document || '')
    const [password, setPassword] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [selectedAccountErrorMessage] = useState('')
    const [cpfErrorMessage, setCpfErrorMessage] = useState('')
    const [remainingAttempts, setRemainingAttempts] = useState(null)
    const [preLoginError, setPreLoginError] = useState(null)
    const [loginError, setLoginError] = useState(null)
    const [isCpfValid, setIsCpfValid] = useState(false)
    const { audit, setFirstLogin } = useDigitalAccountUser()
    const [preLoginResponse, setPreLoginResponse] = useState({})
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [loginStep, setLoginStep] = useState(LOGIN_SUBSTEPS.LOGIN_PASSWORD)
    const [preLoginTimer, setPreLoginTimer] = useState(null)
    const [contentToShow, setContentToShow] = useState(null)
    const [userEmail, setUserEmail] = useState('')
    const [showRegisterModal, setShowRegisterModal] = useState(false)
    const [registerInput, setRegisterInput] = useState({
        name: '',
        email: '',
        phone: ''
    })
    const [registerError, setRegisterError] = useState({})
    const [phoneFormat, setPhoneFormat] = useState('(##) ####.#####')

    useEffect(() => {
        setRegisterInput({
            name: '',
            email: '',
            phone: ''
        })
    }, [showRegisterModal])

    useEffect(() => {
        registerInput.phone.length > 10
            ? setPhoneFormat('(##) #####-####')
            : setPhoneFormat('(##) ####-#####')
    }, [registerInput.phone])

    const isEmailValid = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        return emailRegex.test(email)
    }

    const isPhoneValid = (phone) => {
        return (phone.length === 10 || phone.length === 11) && !/^0+$/.test(phone)
    }

    const registerFieldsFilled = useMemo(() => {
        const isNameValid = registerInput.name.length >= 5

        const newErrors = {}
        if (registerInput.name && !isNameValid) {
            Object.assign(newErrors, { name: 'Nome inválido' })
        } else {
            Object.assign(newErrors, { name: '' })
        }

        if (registerInput.email && !isEmailValid(registerInput.email)) {
            Object.assign(newErrors, { email: 'E-mail inválido' })
        } else {
            Object.assign(newErrors, { email: '' })
        }

        if (registerInput.phone && !isPhoneValid(registerInput.phone)) {
            Object.assign(newErrors, { phone: 'Telefone inválido' })
        } else {
            Object.assign(newErrors, { phone: '' })
        }

        setRegisterError({
            ...registerError,
            ...newErrors
        })

        return isNameValid && isEmailValid(registerInput.email) && isPhoneValid(registerInput.phone)
    }, [registerInput])

    const handleCloseRegisterModal = () => {
        setShowRegisterModal(false)
    }

    const handleRegisterInput = (field) => (e) => {
        setRegisterInput({
            ...registerInput,
            [field]: e.target ? e.target.value : e.value
        })
    }

    const handleRegister = () => {
        window.open(
            `https://api.whatsapp.com/send?phone=5511942232039&text=${encodeURI(
                'Olá, quero fazer meu cadastro na plataforma de fornecedores. Podem me ajudar, por favor? Seguem os meus dados: \n\nNome: ' +
                    registerInput.name +
                    '\nE-mail: ' +
                    registerInput.email +
                    '\nTelefone: ' +
                    registerInput.phone
            )}`
        )

        handleCloseRegisterModal()
    }

    const handleOnClickBottomLink = () => {
        window.open(
            `https://api.whatsapp.com/send?phone=5511942232039&text=${encodeURI(
                'Olá, quero fazer meu cadastro na plataforma de fornecedores. Podem me ajudar, por favor?'
            )}`
        )
    }

    const possiblePreLoginErrors = [
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_NOT_AVAILABLE.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_HAS_NO_ACTIVE_ACCOUNTS.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_BLOCKED.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_NOT_FOUND.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.BLOCKED_BY_PIN_VALIDATION.code
    ]

    const possibleResetPasswordErrors = [
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_NOT_FOUND.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_CPF.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_NOT_BLOCKED.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.EMAIL_NOT_FOUND.code
    ]

    const possibleLoginErrors = [
        ...possibleResetPasswordErrors,
        ...possiblePreLoginErrors,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.ACCOUNT_NOT_AVAILABLE.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_PASSWORD.code,
        DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_TOKEN.code
    ]

    const MAX_PRE_LOGIN_TIME_MS = 2.5 * 60 * 1000

    useEffect(() => {
        if (!cpf) {
            router.push(ROUTE_DIGITAL_ACCOUNT_NEW_LOGIN)
        }
    }, [])

    useEffect(() => {
        if (preLoginResponse) {
            setPreLoginTimer(MAX_PRE_LOGIN_TIME_MS)
        }
    }, [preLoginResponse])

    useEffect(() => {
        if (preLoginTimer === 0) {
            handleBackToLogin()
        } else {
            if (preLoginTimer) {
                const interval = setInterval(() => {
                    setPreLoginTimer(preLoginTimer - 1000)
                }, 1000)
                return () => clearInterval(interval)
            }
        }
    }, [preLoginTimer])

    const handleCpfChange = (e) => {
        setPreLoginError(null)
        setCpfErrorMessage('')

        const value = e.value
        setCpf(onlyDigits(value))
        const isValid = cpfValidator.isValid(onlyDigits(value))
        setIsCpfValid(isValid)
        if (isValid && cpf.length === CPF_LENGTH) {
            setErrorMessage('')
        }
    }

    const handlePasswordChange = (e) => {
        const value = e.target.value
        setPassword(value)

        if (errorMessage) {
            setErrorMessage('')
        }
    }

    const handleBackToLogin = () => {
        setLoginError(null)
        setPreLoginError(null)
        setPreLoginTimer(null)
        setCpf('')
        setPassword('')
        setSelectedAccount(null)
        setRemainingAttempts(null)
        setPreLoginResponse({})
        setUserEmail(null)
        setContentToShow(null)
        setLoginStep(LOGIN_SUBSTEPS.LOGIN_PASSWORD)
    }

    const renderContent = () => {
        switch (loginStep) {
            case LOGIN_SUBSTEPS.LOGIN_RESET_PASSWORD:
                return renderLoginResetPassword()
            case LOGIN_SUBSTEPS.LOGIN_LOADING:
                return renderLoginLoading()
            case LOGIN_SUBSTEPS.LOGIN_ERROR:
                return renderLoginError()
            case LOGIN_SUBSTEPS.LOGIN_SELECT_ACCOUNT:
                return renderSelectAccount()
            case LOGIN_SUBSTEPS.LOGIN_AUTO_REDIRECT:
                return handleLogin()
            default:
                return renderLogin()
        }
    }

    const handleOnClickUnlockUser = async () => {
        const isValid = cpfValidator.isValid(cpf)
        if (!isValid) {
            setCpfErrorMessage(DIGITAL_ACCOUNT_NETWORK_ERRORS.INVALID_CPF.message)
        } else {
            const response = await unlockUserAccountRequest(cpf)
            if (!response.success) {
                setShowModal(true)
            }
            setUserEmail(response.email)
            setContentToShow(RESET_PASSWORD_CONTENT.SUCCESS_USER_BLOCKED_EMAIL)
            setLoginStep(LOGIN_SUBSTEPS.LOGIN_RESET_PASSWORD)
        }
    }

    const handleForgotPassword = async () => {
        const isValid = cpfValidator.isValid(cpf)
        if (!isValid) {
            setCpfErrorMessage(DIGITAL_ACCOUNT_NETWORK_ERRORS.INVALID_CPF.message)
        } else {
            const response = await requestForgotPassword(cpf)
            if (!response.success) {
                setLoginError(response.code)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_ERROR)
            } else {
                setUserEmail(response.email)
                setContentToShow(RESET_PASSWORD_CONTENT.SUCCESS_FORGOT_PASSWORD_EMAIL)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_RESET_PASSWORD)
            }
        }
    }

    const handleAccountSelect = (value) => {
        setSelectedAccount(value)
    }

    const handleCpfValidate = async () => {
        const isValid = cpfValidator.isValid(cpf)
        setIsCpfValid(isValid)
        if (!isValid) {
            setCpfErrorMessage(DIGITAL_ACCOUNT_NETWORK_ERRORS.INVALID_CPF.message)
            return
        }
    }

    const handleLogin = async () => {
        setLoginStep(LOGIN_SUBSTEPS.LOGIN_LOADING)

        const loginResponse = await requestDigitalAccountLogin(
            preLoginResponse,
            selectedAccount,
            audit
        )

        if (loginResponse.success) {
            registerDigitalAccountCookies(
                loginResponse.access_token,
                new Date(loginResponse.access_token_expires_in),
                loginResponse.audit
            )

            const userNameResponse = await requestUserName()
            if (!userNameResponse.success) {
                handleBackToLogin()
                return setShowModal(true)
            }

            //Enhance supplier data
            enhanceDigitalAccountUser(userNameResponse.data)

            setFirstLogin(loginResponse.first_login)
            authContext.setAuthenticated(true)

            const roles = getDigitalAccountUser().roles
            const hasDigitalAccount = roles?.includes('DIGITAL_ACCOUNT')
            hasDigitalAccount && setAuthenticated(true)

            sessionStorage.setItem('cc', Buffer.from(onlyDigits(cpf)).toString('base64'))
            sessionStorage.setItem('ccaccountid', Buffer.from(selectedAccount).toString('base64'))
            sessionStorage.setItem(
                'should-set-transaction-password',
                loginResponse.should_set_transaction_password
            )

            authContext.setAuthenticated(true)
            const user = getDigitalAccountUser()

            user.status === SupplierStatus.QUALIFIED.name
                ? router.push(ROUTE_WELCOME)
                : router.push(hasDigitalAccount ? ROUTE_DIGITAL_ACCOUNT_DASHBOARD : ROUTE_DASHBOARD)

            return
        } else {
            if (possibleLoginErrors.includes(loginResponse.code)) {
                setLoginError(loginResponse.code)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_ERROR)
            } else {
                setShowModal(true)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_SELECT_ACCOUNT)
                return
            }
        }
    }

    const handlePreLogin = async () => {
        if (password.length <= PASSWORD_LENGTH && password.length >= MIN_PASSWORD_LENGTH) {
            handlePasswordValidate(password)
        } else {
            setErrorMessage(DIGITAL_ACCOUNT_NETWORK_ERRORS.PASSWORD_LENGTH.message)
            return false
        }
    }

    const handlePasswordValidate = async (password) => {
        setLoginStep(LOGIN_SUBSTEPS.LOGIN_LOADING)

        const preLoginResponse = await requestDigitalAccountPreLogin(cpf, password, audit)

        if (preLoginResponse.success) {
            setPreLoginResponse(preLoginResponse)
            if (preLoginResponse.availableAccounts?.length === 1) {
                setSelectedAccount(() => preLoginResponse.availableAccounts[0].id)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_AUTO_REDIRECT)
            } else {
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_SELECT_ACCOUNT)
            }
            return
        } else {
            if (
                possiblePreLoginErrors.includes(preLoginResponse.code) ||
                preLoginResponse.code === DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_NOT_FOUND.code
            ) {
                setPreLoginError(preLoginResponse.code)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_ERROR)
                return
            } else if (
                preLoginResponse.code === DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_PASSWORD.code
            ) {
                setRemainingAttempts(preLoginResponse.attemptsLeft)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_PASSWORD)
                return
            } else {
                setShowModal(true)
                setLoginStep(LOGIN_SUBSTEPS.LOGIN_PASSWORD)
                return
            }
        }
    }

    const renderLoginLoading = () => {
        return (
            <Box className={styles.contentDiv}>
                <Text className={styles.title}>Seja bem-vindo!</Text>
                <div className={styles.loadingDiv}>
                    <div className={styles.loading}>
                        <Spinner size={'60px'} color={'#6a53b2'} />
                    </div>
                    <Text weight={'v_500'} className={styles.loadingText}>
                        Por favor aguarde, estamos validando o seu acesso...
                    </Text>
                </div>
            </Box>
        )
    }

    const renderLoginError = () => {
        const isPreLoginError = preLoginError && possiblePreLoginErrors.includes(preLoginError)
        const errorCode = isPreLoginError ? preLoginError : loginError

        const error = DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS[errorCode] || {}

        if (errorCode === DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_BLOCKED.code) {
            return <UserBlocked onClickUnlockUser={handleOnClickUnlockUser} />
        }

        return (
            <div className={[styles.contentRight, styles.attemptsError].join(' ')}>
                <div className={styles.blockedWrapper}>
                    <div className={styles.feedBackIconWrapper}>
                        <Image src={error?.img || ''} width="60" height="60" />
                    </div>
                    <Text className={styles.title}>
                        <span>{error?.message}</span>
                    </Text>
                    <div className={styles.modalTextWrapper}>
                        <Text weight={'v_500'} className={styles.modalText}>
                            {error?.description}
                        </Text>
                    </div>

                    {preLoginError === DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_BLOCKED.code ? (
                        <div className={styles.buttonsWrapper}>
                            <Button
                                onClick={() =>
                                    router.push(
                                        `https://api.whatsapp.com/send?phone=5511942232039&text=${encodeURI(
                                            'Olá! Minha conta foi bloqueada e preciso de ajuda ⚠️'
                                        )}`
                                    )
                                }
                                className={styles.buttonAccept}
                                size={'large'}>
                                Desbloquear minha conta
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className={styles.buttonsWrapper}>
                                <Button
                                    onClick={() =>
                                        router.push(
                                            `https://api.whatsapp.com/send?phone=5511942232039&text=${encodeURI(
                                                'Olá! Não estou conseguindo acessar minha conta ⚠️'
                                            )}`
                                        )
                                    }
                                    className={styles.buttonAccept}
                                    size={'large'}>
                                    Solicitar ajuda
                                </Button>
                            </div>

                            <Text
                                size={'v_12'}
                                color={'blue_600'}
                                weight={'bold'}
                                data-testid={'back-button'}
                                onClick={handleBackToLogin}
                                className={styles.backButton}>
                                Voltar
                            </Text>
                        </>
                    )}
                </div>
            </div>
        )
    }

    const renderLoginResetPassword = () => {
        const contents = {
            USER_BLOCKED: <UserBlocked onClickUnlockUser={handleOnClickUnlockUser} />,
            SUCCESS_FORGOT_PASSWORD_EMAIL: <ForgotPassword userEmail={userEmail} />,
            SUCCESS_USER_BLOCKED_EMAIL: <UnlockUser userEmail={userEmail} />
        }
        return contents[contentToShow] || null
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handlePasswordValidate(password)
        }
    }

    const renderLogin = () => {
        return (
            <Box className={[styles.contentDiv, styles.bordered]}>
                <Text className={styles.title}>Seja bem-vindo!</Text>
                <Text weight={'v_500'} className={styles.subtitle}>
                    Insira seus dados para acessar a plataforma
                </Text>
                <div className={styles.cpfInputWrapper}>
                    <InputText
                        error={!isCpfValid && cpfErrorMessage}
                        errorMessage={!isCpfValid && cpfErrorMessage}
                        label="Login (CPF)*"
                        value={cpf}
                        id="cpf"
                        onKeyDown={handleCpfValidate}
                        onChange={handleCpfChange}
                        maskOptions={{ format: '###.###.###-##' }}
                    />
                    {preLoginError === DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_NOT_FOUND.code &&
                        !remainingAttempts && (
                            <Text className={styles.userNotFoundText}>
                                CPF não identificado. Cadastre-se para acessar
                            </Text>
                        )}
                </div>
                <div className={styles.cpfInputWrapper}>
                    <InputPassword
                        name="password"
                        id="password"
                        value={password}
                        onKeyDown={handleKeyDown}
                        onChange={handlePasswordChange}
                        error={errorMessage}
                        errorMessage={errorMessage}
                        label="Senha *"
                        maxLength={PASSWORD_LENGTH}
                    />
                    {remainingAttempts !== null &&
                        preLoginError !==
                            DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_NOT_FOUND.code && (
                            <Text className={styles.invalidPasswordText}>
                                Senha inválida! Você tem apenas mais
                                <span> {remainingAttempts} tentativa(s)</span> de inserir a senha
                                correta <span>antes que seu acesso seja bloqueado.</span>
                            </Text>
                        )}
                    <Text
                        size={'v_12'}
                        color={'blue_600'}
                        weight={'bold'}
                        data-testid={'forgot-password'}
                        onClick={handleForgotPassword}
                        className={styles.forgotPassword}>
                        Esqueci minha senha
                    </Text>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button
                        className={[
                            `${styles.buttonAccept} ${
                                !cpf || !isCpfValid ? styles.buttonDisabled : ''
                            }`
                        ]}
                        size={'large'}
                        onClick={handlePreLogin}
                        disabled={!cpf || !isCpfValid}>
                        Acessar a plataforma
                    </Button>
                </div>
                <div
                    className={styles.questions}
                    rel="noreferrer"
                    onKeyDown={() => {
                        setShowRegisterModal(true)
                    }}
                    role={'button'}
                    tabIndex={0}
                    onClick={() => setShowRegisterModal(true)}>
                    Ainda não tem uma conta? Cadastre-se aqui!
                </div>
            </Box>
        )
    }

    const renderSelectAccount = () => {
        return (
            <Box className={[styles.contentDiv, styles.bordered]}>
                <Text className={styles.title}>
                    Olá <span>{preLoginResponse?.name}</span>!
                </Text>
                <Text weight={'v_500'} className={styles.subtitle}>
                    Selecione a empresa que deseja fazer login
                </Text>
                <div className={styles.selectAccountInputWrapper}>
                    <Dropdown
                        id="select-account"
                        items={[]}
                        label={'Empresa (CNPJ)*'}
                        className={styles.options_hover}
                        error={selectedAccountErrorMessage}
                        errorMessage={selectedAccountErrorMessage}
                        onChange={handleAccountSelect}
                        value={selectedAccount}>
                        <option value="">Selecione a empresa</option>
                        {preLoginResponse?.availableAccounts?.map((item, index) => (
                            <option key={index} value={item.id}>
                                {formatCpfCnpjString(item.document)}
                            </option>
                        ))}
                    </Dropdown>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button
                        className={[
                            `${styles.buttonAccept} ${
                                !selectedAccount ? styles.buttonDisabled : ''
                            }`
                        ]}
                        size={'large'}
                        onClick={handleLogin}
                        disabled={!selectedAccount}>
                        Continuar
                    </Button>
                </div>
                <Text
                    size={'v_12'}
                    color={'blue_600'}
                    weight={'bold'}
                    data-testid={'back-button'}
                    onClick={handleBackToLogin}
                    className={styles.backButton}>
                    Voltar
                </Text>
                <a
                    className={styles.questions}
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=5511942232039&text=Ol%C3%A1"
                    rel="noreferrer">
                    Alguma dúvida? Entre em contato conosco!
                </a>
            </Box>
        )
    }

    const renderRegisterModalContent = () => {
        return (
            <div className={styles.centerContent}>
                <button className={styles.closeModalButton} onClick={handleCloseRegisterModal}>
                    <Image src={'/images/icon_close.svg'} width="16" height="16" />
                </button>

                <Text className={styles.title} weight={'v_700'} size={'v_24'}>
                    Inicie seu cadastro e antecipe com mais facilidade!
                </Text>
                <Text weight={'v_400'} className={styles.subtitle} size={'v_14'}>
                    Preencha seus dados e <b>continue seu cadastro pelo WhatsApp.</b> Um jeito
                    prático e <b>seguro com total suporte</b> e sem complicações!
                </Text>

                <div className={styles.inputWrapper}>
                    <InputText
                        label={'Nome completo*'}
                        error={registerError.name}
                        errorMessage={registerError.name}
                        value={registerInput.name}
                        onChange={handleRegisterInput('name')}
                    />
                    <InputText
                        label={'E-mail*'}
                        error={registerError.email}
                        errorMessage={registerError.email}
                        value={registerInput.email}
                        onChange={handleRegisterInput('email')}
                    />
                    <InputText
                        label={'Telefone celular*'}
                        error={registerError.phone}
                        errorMessage={registerError.phone}
                        value={registerInput.phone}
                        onChange={handleRegisterInput('phone')}
                        maskOptions={{ displayType: 'input', format: phoneFormat }}
                    />
                </div>

                <div className={styles.alertWrapper}>
                    <Alert className={styles.alert} type={'success'}>
                        <Text className={styles.alertText}>
                            Você <b>será direcionado ao WhatsApp para finalizar seu cadastro</b> de
                            forma criptografada e segura.
                        </Text>
                    </Alert>
                </div>

                <div className={styles.buttonsWrapper}>
                    <Button
                        className={[
                            `${styles.buttonRegister} ${
                                !registerFieldsFilled ? styles.buttonDisabled : ''
                            }`
                        ]}
                        size={'large'}
                        onClick={handleRegister}
                        disabled={!registerFieldsFilled}>
                        Iniciar meu cadastro
                    </Button>

                    <Button
                        className={styles.bottomLinkText}
                        variant="link"
                        onClick={handleOnClickBottomLink}>
                        Alguma dúvida? Entre em contato conosco!
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <>
            <Container xs sm className={styles.loginContainer}>
                <Row>
                    <Col lg={12}>
                        <Box className={styles.loginWelcome}>
                            <div className={styles.content}>
                                <div className={[`${styles.contentLeft} ${styles.bordered}`]}>
                                    <img
                                        className={[`${styles.imageFluid} ${styles.bordered}`]}
                                        src={'/images/image-man-mobile.png'}
                                        alt={'Condolivre Pag'}
                                    />
                                    <img
                                        className={styles.floatingLogo}
                                        height={30}
                                        src={'/images/condolivrepag_2.png'}
                                        alt={'Condolivre Pag'}
                                    />
                                </div>
                                <div className={[`${styles.contentRight} ${styles.bordered}`]}>
                                    {renderContent()}
                                </div>
                            </div>
                        </Box>
                    </Col>
                </Row>
                <CustomModal
                    showModal={showModal}
                    handleTryAgain={() => {
                        setShowModal(false)
                    }}
                />
            </Container>

            <Modal
                show={showRegisterModal}
                onClose={handleCloseRegisterModal}
                className={styles.registerModal}>
                {renderRegisterModalContent()}
            </Modal>
        </>
    )
}
