import Modal from '@/components/Modal/Modal'
import { Box, Text, Button } from 'condolivre-ds'
import Image from 'next/image'
import styles from './index.module.scss'
import PropTypes from 'prop-types'

export default function CustomModal({ showModal, handleTryAgain, title, message }) {
    return (
        <Modal className={styles.modal} show={showModal}>
            <Box className={styles.modalContent}>
                <Image src={'/images/emoji_sm_erro.png'} width="60" height="60" />
                <Text className={styles.modalTitle}>{title}</Text>
                <Box className={styles.modalTextWrapper}>
                    <Text weight={'v_500'} className={styles.modalText}>
                        {message}
                    </Text>
                </Box>
                <Button
                    id="btn-try-again"
                    className={styles.buttonTryagain}
                    onClick={() => {
                        handleTryAgain()
                    }}>
                    Tentar novamente
                </Button>
            </Box>
        </Modal>
    )
}

CustomModal.defaultProps = {
    handleTryAgain: () => {},
    title: 'Ocorreu um erro no processamento',
    message: 'Houve um problema de conexão com nossos servidores tente novamente.'
}

CustomModal.propTypes = {
    handleTryAgain: PropTypes.func,
    showModal: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
}
