import SimpleContent from '@/pages/digital-account/components/simple-content/simple-content'
import styles from './ForgotPassword.module.scss'
import PropTypes from 'prop-types'
import { Button, Text } from 'condolivre-ds'

const ForgotPassword = ({ userEmail }) => {
    return (
        <SimpleContent
            className={styles.forgotPasswordContent}
            title={'Esqueceu sua senha?'}
            subtitle={'Enviamos um link para redefinição de senha para o seu email:'}
            iconPath={'/images/emoji_md_attention.png'}>
            <div className={styles.successResendEmailChildren}>
                <Text className={styles.successResendEmailChildrenTitle}>{userEmail}</Text>
                <Button
                    className={styles.buttonContactSupport}
                    variant="link"
                    data-testid="button-contact-support"
                    onClick={() =>
                        window.open(
                            `https://api.whatsapp.com/send?phone=5511942232039&text=${encodeURI(
                                'Olá, estou tentando redefinir minha senha, mas o e-mail cadastrado não é meu. Podem me ajudar, por favor?'
                            )}`,
                            '_blank'
                        )
                    }>
                    Não é seu e-mail? Entre em contato com nossa equipe clicando <b>AQUI</b>
                </Button>
            </div>
        </SimpleContent>
    )
}

ForgotPassword.propTypes = {
    userEmail: PropTypes.string.isRequired
}

export default ForgotPassword
