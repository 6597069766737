import SimpleContent from '@/pages/digital-account/components/simple-content/simple-content'
import styles from './UserBlocked.module.scss'
import PropTypes from 'prop-types'

const UserBlocked = ({ onClickUnlockUser }) => {
    return (
        <SimpleContent
            className={styles.userBlockedContent}
            title={'Usuário bloqueado!'}
            subtitle={
                'Ops! Parece que você inseriu a senha errada várias vezes, por razões de segurança, seu acesso foi bloqueado temporariamente.'
            }
            iconPath={'/images/emoji_sm_erro.svg'}
            primaryButtonText="Desbloquear minha conta"
            onClickPrimaryButton={onClickUnlockUser}
        />
    )
}

UserBlocked.propTypes = {
    onClickUnlockUser: PropTypes.func.isRequired
}

export default UserBlocked
