import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { registerDigitalAccountCookies } from './cookies'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'

export default function BaasService({ unobstrusive } = { unobstrusive: false }) {
    const service = axios.create({
        baseURL: `${process.env.API_URL}/baas`,
        delayed: true
    })

    // Add a request interceptor
    service.interceptors.request.use(
        function (config) {
            if (config.url !== '/login/refresh') {
                const cookies = new Cookies()
                const token = cookies.get('da-session')
                config.headers.Authorization = 'Bearer ' + token
            }
            !unobstrusive && NProgress.start()

            return config
        },
        function (error) {
            !unobstrusive && NProgress.done()
            return Promise.reject(error)
        }
    )

    // Add a response interceptor
    service.interceptors.response.use(
        function (response) {
            if (response?.data?.status === 403 || response?.data?.status === 401) {
                window.dispatchEvent(new Event('lock-screen'))
            } else {
                try {
                    const access_token = response.headers['access-token']

                    if (access_token) {
                        const data = jwtDecode(access_token)
                        registerDigitalAccountCookies(
                            access_token,
                            new Date(data.exp * 1000),
                            data.audit
                        )
                    }
                } catch {
                    // Could not refresh token, continue with the request
                }

                !unobstrusive && NProgress.done()
                return response
            }
        },
        async function (error) {
            !unobstrusive && NProgress.done()

            if (error.response.status === 401) {
                const cookies = new Cookies()
                const refresh_token = cookies.get('da-refresh')
                const audit = cookies.get('da-audit')

                if (error.config.url !== '/login/refresh' && refresh_token && audit) {
                    let refreshed = false

                    await BaasService({ unobstrusive: true })
                        .post(
                            '/login/refresh',
                            {
                                audit
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${refresh_token}`
                                }
                            }
                        )
                        .then((response) => {
                            if (response.status === 201) {
                                registerDigitalAccountCookies(
                                    response.data.data.access_token,
                                    new Date(response.data.data.access_token_expires_in)
                                )

                                refreshed = true
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                    if (refreshed) {
                        return service.request(error.config)
                    }
                }

                window.dispatchEvent(new Event('lock-screen'))
            }
            return Promise.reject(error)
        }
    )

    return service
}

BaasService.defaultProps = {
    unobstrusive: false
}

BaasService.PropTypes = {
    unobstrusive: PropTypes.bool
}
