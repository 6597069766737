import Image from 'next/image'
import styles from './WhatsAppFloatingButton.module.scss'
import PropTypes from 'prop-types'

const WhatsAppFloatingButton = ({ message }) => {
    return (
        <div>
            <a
                data-testid={'whatsApp-floating-button'}
                className={styles.whatsappCtaFloatingButton}
                href={`https://api.whatsapp.com/send?phone=5511942232039&text=${encodeURI(
                    message
                )}`}
                target="_blank"
                rel="noreferrer">
                <Image src="/images/whatsapp-icon.png" width="70" height="70" />
            </a>
        </div>
    )
}

WhatsAppFloatingButton.defaultProps = {
    message: 'Olá'
}

WhatsAppFloatingButton.propTypes = {
    message: PropTypes.string
}

export default WhatsAppFloatingButton
