import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import UnauthenticatedLayout from '@/components/UnauthenticatedLayout/UnauthenticatedLayout'
import Head from 'next/head'
import Welcome from './components/welcome/index'
import WhatsAppFloatingButton from '@/components/WhatsAppFloatingButton/WhatsAppFloatingButton'
import { STEPS } from './constants'

function DigitalAccount() {
    const router = useRouter()

    useEffect(() => {
        if ((window?.location?.host || '').startsWith('fornecedores.')) {
            window.location = window.location.href.replace('fornecedores.', 'fornecedor.')
        }
    }, [])

    return (
        <UnauthenticatedLayout isAuthenticated>
            <Head>
                <title>Conta Digital | Condolivre</title>
            </Head>

            {
                {
                    [STEPS.LOGIN]: <Welcome />,
                    [undefined]: <Welcome />
                }[router.query.step]
            }

            <WhatsAppFloatingButton message="Olá! Estou tentando fazer login na minha conta e gostaria de esclarecer uma dúvida. 🔑" />
        </UnauthenticatedLayout>
    )
}

export default DigitalAccount
