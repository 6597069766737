import styles from './simple-content.module.scss'
import Proptypes from 'prop-types'
import { Button, Text, Spinner } from 'condolivre-ds'

const buttonsDirection = {
    ROW: 'row',
    COLUMN: 'column'
}

const SimpleContent = ({
    iconPath,
    title,
    subtitle,
    children,
    onClickSecondaryButton,
    secondaryButtonText,
    isLoadingSecondaryButton,
    onClickPrimaryButton,
    primaryButtonText,
    isLoadingPrimaryButton,
    className,
    buttonsDirection
}) => {
    return (
        <>
            <div className={className}>
                <div className={[styles.mainIconWrapper, 'simple-content-icon'].join(' ')}>
                    <img src={iconPath} alt="main-icon" width={78} height={78} />
                </div>
                <div className={styles.titleContainer}>
                    {title && (
                        <Text className={[styles.title, 'simple-content-title'].join(' ')}>
                            {title}
                        </Text>
                    )}
                    {subtitle && (
                        <Text className={[styles.subtitle, 'simple-content-subtitle'].join(' ')}>
                            {subtitle}
                        </Text>
                    )}
                </div>
                {children}
                <div
                    className={[
                        styles.buttonsContainer,
                        buttonsDirection === 'row'
                            ? styles.buttonsDirectionRow
                            : styles.buttonsDirectionColumn
                    ].join(' ')}
                    data-testid={'button-container'}>
                    {secondaryButtonText && (
                        <Button
                            className={[
                                styles.secondaryButton,
                                'simple-content-secondary-button'
                            ].join(' ')}
                            onClick={onClickSecondaryButton}
                            variant={'primary'}
                            size={'normal'}
                            disabled={isLoadingSecondaryButton}>
                            {isLoadingSecondaryButton ? (
                                <div className={styles.loadingSpinner}>
                                    <Spinner size={'20px'} color={'white'} />
                                </div>
                            ) : (
                                secondaryButtonText
                            )}
                        </Button>
                    )}
                    {primaryButtonText && (
                        <Button
                            data-testid={'simple-content-primary-btn'}
                            className={[styles.primaryButton, 'simple-content-primary-button'].join(
                                ' '
                            )}
                            onClick={onClickPrimaryButton}
                            variant={'ghost'}
                            size={'normal'}
                            disabled={isLoadingPrimaryButton}>
                            {isLoadingPrimaryButton ? (
                                <div className={styles.loadingSpinner}>
                                    <Spinner size={'25px'} color={'white'} />
                                </div>
                            ) : (
                                primaryButtonText
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}

SimpleContent.defaultProps = {
    showCloseButton: true,
    onDelete: () => {},
    onClickSecondaryButton: () => {},
    isLoadingPrimaryButton: false,
    onClickPrimaryButton: () => {},
    isLoadingSecondaryButton: false,
    toggleModal: () => {},
    buttonsDirection: buttonsDirection.ROW,
    className: ''
}

SimpleContent.propTypes = {
    className: Proptypes.string,
    iconPath: Proptypes.string,
    title: Proptypes.string,
    subtitle: Proptypes.string,
    children: Proptypes.node,
    primaryButtonText: Proptypes.string,
    secondaryButtonText: Proptypes.string,
    onClickPrimaryButton: Proptypes.func,
    onClickSecondaryButton: Proptypes.func,
    isLoadingPrimaryButton: Proptypes.bool,
    isLoadingSecondaryButton: Proptypes.bool,
    buttonsDirection: Proptypes.oneOf(Object.values(buttonsDirection))
}

export default SimpleContent
