import React from 'react'
import styles from './UnauthenticatedLayout.module.scss'
import PropTypes from 'prop-types'
import Footer from '@/components/Footer/Footer'
// TODO: remove showLogoutButton conditions when all old front pages are updated

function UnauthenticatedLayout({ children, footer }) {
    return (
        <div className={styles.background}>
            <div className={styles.header}>
                <img
                    className={styles.headerImg}
                    height={20}
                    src={'/images/condolivrepag_1.png'}
                    alt={'Condolivre Pag'}
                />
            </div>
            {children}
            {footer && <Footer data-testid={'footer'} />}
        </div>
    )
}

UnauthenticatedLayout.propTypes = {
    isAuthenticated: PropTypes.bool,
    showSidebar: PropTypes.bool,
    children: PropTypes.node,
    header: PropTypes.bool,
    className: PropTypes.any,
    headerNav: PropTypes.bool,
    backAction: PropTypes.func,
    footer: PropTypes.bool
}

UnauthenticatedLayout.defaultProps = {
    showSidebar: true,
    isAuthenticated: false,
    headerNav: true,
    footer: true
}

export default UnauthenticatedLayout
